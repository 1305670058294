import styled from "styled-components";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 900px;
    width: 100%;
`;

export default Content;
