import React, { useCallback, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";

import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";

import UnstyledCopyIcon from "../../svgs/CopyIcon.svg";
import UnstyledSuccessIcon from "../../svgs/SuccessIcon.svg";

const Container = styled.div`
    margin: 0px;
    width: 500px;
    border-radius: ${Spacing.SPACING_1};
    padding: ${Spacing.SPACING_2};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${Color.BACKGROUND_LIGHT_2};
    margin-bottom: ${Spacing.SPACING_3};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        width: 100%;
    }
`;

const Email = styled.p`
    color: ${Color.BRAND_1};
    margin: 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
`;

const CopyButton = styled.div`
    border: 2px solid black;
    background-color: ${Color.BRAND_1};
    border-color: ${Color.BRAND_1};
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
`;

const CopyIcon = styled(UnstyledCopyIcon)`
    path {
        stroke: ${Color.TEXT_LIGHT_1};
    }
`;

const SuccessIcon = styled(UnstyledSuccessIcon)`
    path {
        stroke: ${Color.TEXT_LIGHT_1};
    }
`;

const Contact = () => {
    const contactEmail = "team@learncmd.io";
    const [isSuccess, setIsSuccess] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const onClick = useCallback(() => {
        navigator.clipboard.writeText(contactEmail);

        setIsSuccess(true);

        window.setTimeout(() => {
            if (isMounted.current) {
                setIsSuccess(false);
            }
        }, 5000);
    }, [contactEmail, isMounted]);

    return (
        <Container>
            <Email>{contactEmail}</Email>
            <CopyButton onClick={onClick}>
                {isSuccess ? (
                    <SuccessIcon width={20} height={20} />
                ) : (
                    <CopyIcon width={20} height={20} />
                )}
            </CopyButton>
        </Container>
    );
};

export default Contact;
