import grga from "../images/Grga.jpeg";
import alen from "../images/Alen.jpeg";

export const founders = [
    {
        name: "Alen Čaljkušić",
        role: "Backend Developer / DevOps",
        image: alen,
        description: `
            Experienced in developer, DevOps, and SRE roles. Worked for
            companies ranging from startups to some of the biggest IT
            companies out there such as Ericsson and Activision Blizzard.
            Deeply passionate about Linux, Cloud, and related technologies.
        `,
    },
    {
        name: "Ivan Grga",
        role: "Full Stack Developer",
        image: grga,
        description: `
            Senior full stack developer with over five years of experience.
            Worked as a developer and team lead. Helped build various
            startups and platforms. Currently building and maintaining
            things on Tripadvisor.
        `,
    },
];
