import React from "react";
import Contact from "../components/AboutUs/Contact";
import Founder from "../components/AboutUs/Founder";
import Content from "../components/Common/Content";
import Layout from "../components/Common/Layout";
import Main from "../components/Common/Main";
import Paragraph from "../components/Common/Paragrpah";
import Section from "../components/Common/Section";
import Subtitle from "../components/Common/Subtitle";
import Title from "../components/Common/Title";
import Spacing from "../constants/Spacing";
import { founders } from "../data/aboutUsData";

const AboutUs = () => {
    return (
        <Layout title="About Us">
            <Main>
                <Section>
                    <Content>
                        <Title
                            marginTop={Spacing.SPACING_3}
                            marginBottom={Spacing.SPACING_3}
                        >
                            About Us
                        </Title>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            Contact
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            We would love to hear from you, so feel free to
                            contact us for any reason at:
                        </Paragraph>
                        <Contact />
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            Motivation
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            In all my years working as a software developer and
                            DevOps engineer, I have noticed that{" "}
                            <strong>
                                many great developers are not comfortable using
                                the Linux command line interface.
                            </strong>{" "}
                            Usually, it is something that they never really took
                            the time to master.
                            <br />
                            <br /> Most developers are familiar with the basics
                            like navigating the file system, moving files
                            around, basic text editing, and some entry-level
                            commands. Some self-taught coders are not familiar
                            with command line interface in general and feel
                            intimidated.{" "}
                            <strong>
                                Many find it cryptic and confusing or just not
                                that useful and avoid it.
                            </strong>{" "}
                            <br />
                            <br />
                            In times of the Cloud, Docker and Kubernetes
                            developers are interacting day-to-day with such
                            technologies using a command line interface. It is
                            no longer enough to be a programming language expert
                            to stay competitive. Developers are expected to
                            deploy and debug complex distributed environments.{" "}
                            <strong>
                                Good understanding and proficiency in the
                                command line are crucial for interacting with
                                machines and systems.
                            </strong>{" "}
                            <br />
                            <br />
                            The internet is full of blog posts, video courses,
                            forum posts, and YouTube videos that explain Linux
                            and command line interface. They do a good job.
                            However,{" "}
                            <strong>
                                teaching is not about showing how to do it. It
                                is about guiding the trainees while they do it.
                            </strong>
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            Product
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            This platform is built to provide the best guidance
                            experience. The learning environment is ready in
                            seconds. If the student breaks it, it is easy to
                            restart it and start all over again. No need to
                            install anything.
                            <br />
                            <br />{" "}
                            <strong>
                                Platform interacts with the student all the
                                time.
                            </strong>{" "}
                            It monitors and guides the student. Interaction is
                            pretty advanced.{" "}
                            <strong>
                                Students can perform the task in many ways and
                                the platform will recognize it.{" "}
                            </strong>
                            Progress it tracked.
                            <br />
                            <br />
                            It provides a depth of knowledge when needed but
                            does not force it on the student. Students can
                            choose how many extra materials they need at the
                            time. Going through lessons and practicing is
                            designed to be fast and easy. In-depth knowledge and
                            explanations are available for all the content but
                            are not forced upon the student.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            Vision
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            Our vision is to build{" "}
                            <strong>one of a kind learning experience</strong>{" "}
                            that will be a one-stop shop for learning Linux and
                            command line interface. This platform is a
                            tailor-made solution with only a single purpose in
                            mind, to be the best interactive learning platform
                            on the market and enable anyone to master the Linux
                            and command line fast and easily.
                        </Paragraph>
                    </Content>
                </Section>
                <Section marginBottom={Spacing.SPACING_7}>
                    <Content>
                        <Title marginBottom={Spacing.SPACING_3}>Founders</Title>
                        {founders.map(
                            ({ name, role, description, image }, index) => {
                                return (
                                    <Founder
                                        key={index}
                                        name={name}
                                        role={role}
                                        image={image}
                                        description={description}
                                    />
                                );
                            }
                        )}
                    </Content>
                </Section>
            </Main>
        </Layout>
    );
};

export default AboutUs;
