import React from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";

interface Props {
    name: string;
    role: string;
    description: string;
    image: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: ${Spacing.SPACING_3};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        flex-direction: column;
    }
`;

const Name = styled.h3`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    color: ${Color.TEXT_DARK_1};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        margin-top: ${Spacing.SPACING_2};
    }
`;

const Role = styled.h4`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0px 0px ${Spacing.SPACING_2} 0px;
    color: ${Color.TEXT_DARK_2};
`;

const Avatar = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: ${Color.TEXT_DARK_2};
    margin-right: ${Spacing.SPACING_2};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: none;
    }
`;

const MobileAvatar = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: ${Color.TEXT_DARK_2};
    margin-right: ${Spacing.SPACING_2};
`;

const MobileAvatarRow = styled.div`
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: ${Spacing.SPACING_3};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: flex;
    }
`;

const MobileAvatarColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const NameAndRoleColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: none;
    }
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Description = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${Color.TEXT_DARK_2};
    margin: 0;
    margin-bottom: ${Spacing.SPACING_2};
`;

const Founder = ({ name, role, description, image }: Props) => {
    return (
        <Container>
            <Avatar src={image} />
            <InfoColumn>
                <MobileAvatarRow>
                    <MobileAvatar src={image} />
                    <MobileAvatarColumn>
                        <Name>{name}</Name>
                        <Role>{role}</Role>
                    </MobileAvatarColumn>
                </MobileAvatarRow>
                <NameAndRoleColumn>
                    <Name>{name}</Name>
                    <Role>{role}</Role>
                </NameAndRoleColumn>
                <Description>{description}</Description>
            </InfoColumn>
        </Container>
    );
};

export default Founder;
